/*
 * @Description: 表单管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:55:04
 */
import request from '@/utils/request'

/**
 * 查询表单列表
 */
export const getFormList = async (data) => request({ url: "/form/getByList", method: "POST", data })

// /**
//  * 根据id查询商品详情
//  */
// export const getNewsById = async (data) => request({ url: "/news/getById", method: "POST", data })

// /**
//  * 根据id列表查询商品列表
//  */
//  export const getNewsByIds = async (data) => request({ url: "/news/getByIds", method: "POST", data })

/**
 * 新增表单
 */
 //export const addForm = async (data) => request({ url: "/form/add", method: "POST", data })
